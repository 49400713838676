import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import InnerPageHero from "../components/inner-page-hero/inner-page-hero"
import VideoHighlight from "../components/video-highlight/video-highlight"
// import UpcomingMatch from "../components/upcoming-match/upcoming-match"
import MatchPoint from "../components/match-point/match-point"

export default function MatchesEvents({ data }) {

  //Match Event Page ACF Data
  const matchEvent = data?.wpPage?.matchEvents

  //Inner Hero Component Data
  const innerHero = matchEvent?.innerHero

  //Livestream Video Component Data
  const latestVidData = matchEvent?.video
  const matchData = matchEvent?.video?.customFields

    //Match Fixtures and Point Table Data
    const matchFixtures = data?.allWpFixture?.nodes

    const pointTab = data?.wp?.siteSettings?.siteSettings?.pointTable

    return (
    <>
      <DefaultLayout data={data}>
        <InnerPageHero innerData={innerHero} />
        {/* <VideoHighlight matchData={matchData} latestVidData={latestVidData} /> */}
        {/* iframe embed - react plugin */}
        {/* <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fsiflvietnam%2Fvideos%2F466741555253337%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}
        {/* <UpcomingMatch upComingMatchData={upComingMatchData} /> */}
        <MatchPoint matchFixtures={matchFixtures} pointTab={pointTab} />
      </DefaultLayout>
    </>
  )
}
export const query = graphql`
  query($slug: String!) {
    wp {
      siteSettings {
        siteSettings {
          pointTable {
            column1Heading
            column2Heading
            column3Heading
            column4Heading
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(fit: COVER, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            fixtureCtaTitle
            leftCtaLink {
              title
              url
            }
            rightCtaLink {
              title
              url
            }
            teamPoints {
              lossNumber
              point
              team
              winNumber
            }
          }
        }
      }
    }
    allWpFixture {
      nodes {
        slug
        title
        uri
        date(formatString: "DD MMMM, YYYY")
        fixtures {
          fixtureDate
          stadium
          awayTeam
          homeTeam
          time
        }
      }
    }
    wpPage(slug: { eq: $slug }) {
      matchEvents {
        innerHero {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData (width: 1920, height: 380)
              }
            }
          }
          title
          leftButton {
            title
            url
          }
          rightButton {
            title
            url
          }
        }
        upcomingFixtures {
          mainHeading
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          matchDetails {
            awayTeamName
            homeTeamName
            venueOfFixture
            timeOfFixture
            awayTeamLogo {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData (width: 134, height: 170)
                }
              }
            }
            homeTeamLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData (width: 134, height: 170)
                }
              }
            }
          }
        }
        video {
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(fit: COVER, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          customFields {
            customFields {
              matchLink {
                url
              }
              thumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
